import React from 'react';

import styled from 'styled-components';
import { useTranslation } from '../../utils/translation';

type Props = { designation: string; href: string; title: string };

const Wrapper = styled.div`
  background-color: var(--theme-background-contrast-background-color);
  color: var(--theme-background-contrast-text-color);
  padding: 20px;
  margin: 16px 0;

  a {
    color: var(--theme-background-contrast-link-color);
  }
`;

const ReadMore = ({ designation, href, title }: React.PropsWithChildren<Props>) => {
  const t = useTranslation();

  return (
    <Wrapper>
      <b>{designation || t('design.readMoreBloc.read_more_label')} :</b>
      <br />
      <a href={href}>{title}</a>
    </Wrapper>
  );
};

export default ReadMore;
