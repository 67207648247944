import React from 'react';
import styled from 'styled-components';
import CirclePlus from '../icons/circle-plus';

const CollapseCircle = styled.div`
  position: relative;
  top: 2px;
  transition: transform 0.3s ease;

  &.mod-extended {
    transform: rotate(45deg);
  }
`;

export type CollapseProps = {
  opened?: boolean;
};

export const Collapse = ({ opened }: CollapseProps) => {
  const [displayed, setDisplayed] = React.useState(false);

  React.useEffect(() => {
    setDisplayed(opened ?? false);
  }, [opened]);

  return (
    <CollapseCircle className={displayed ? 'mod-extended' : ''}>
      <CirclePlus width={26} height={26} color="var(--text-color)" />
    </CollapseCircle>
  );
};
